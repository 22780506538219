 import type { IntegrationUserAgent } from '@pigello/pigello-matrix';

import { IntegrationUserAgentConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetIntegrationUserAgent = (
  options: useGetGenericInstanceProps<IntegrationUserAgent>
) => {
  return useGetInstance<IntegrationUserAgent>({
    ...options,
    config: IntegrationUserAgentConfig,
    modelName: 'integrationuseragent'
  });
};

export const useGetIntegrationUserAgentList = (
  options: useGetGenericListProps<IntegrationUserAgent>
) => {
  return useGetList<IntegrationUserAgent>({
    ...options,
    config: IntegrationUserAgentConfig,
    modelName: 'integrationuseragent'
  });
};
